import React from "react"
import HeadWithPara from './HeadWithPara';
import Button from './Button';

export default function WideCard(props) {
    return (
        <div style={{ backgroundColor: props.color, borderRadius: '30px', padding: '25px', margin: '20px 0' }}>
            <HeadWithPara headerText={props.headerText} paragraphText={props.text} />
            <Button url={props.url} friendlyUrl={props.friendlyUrl} />
        </div>
    );
}