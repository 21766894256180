import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import WideCard from '../components/WideCard'

const kryptoData = require('../../static/json/tjen-krypto.json')

export default class tjenKrypto extends Component {
    render() {
        return (
            <Layout>
                <div className={`container`}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Tjen kryptovaluta - Bitcoin Cash Norge</title>
                        <meta name="description" content="Få gratis kryptovaluta for å registrere deg hos ulike kryptovaluta handelsplattformer." />
                        <link rel="canonical" href="https://bitcoincashnorge.no/tjen-krypto" />
                    </Helmet>
                    <h1>Tjen kryptovaluta</h1>
                    <p>Få gratis kryptovaluta for å registrere deg hos kryptovaluta handelsplattformer. Under finner du ulike måter på hvordan du kan tjene kryptovaluta, uten å måtte gjøre en stor innsats.</p>
                    {kryptoData.map(banner => <WideCard headerText={banner.headerText} text={banner.text} url={banner.url} friendlyUrl={banner.friendlyUrl} color={banner.color} />)}
                </div>
            </Layout>
        )
    }
}
